import { AccessibilityHtmlTags } from '../../../../utils/a11y/consts';
import { Filter } from '../../../../types/types';
import settingsParams from '../../settingsParams';
import { ViewModelFactoryArgs } from '../../state/types';
import { DailyAgendaViewModelFactory } from '../viewModel';

export type HeaderViewModel = {
  title: string;
  titleTagName: string;
  filters: Filter[];
};

export const createHeaderViewModel: DailyAgendaViewModelFactory<
  HeaderViewModel
> = (viewModelFactoryArgs: ViewModelFactoryArgs) => {
  const settings = viewModelFactoryArgs.flowAPI.settings;

  return {
    filters: [],
    title: settings.get(settingsParams.titleText) || 'Class Schedule',
    titleTagName:
      settings.get(settingsParams.titleTagName) ||
      AccessibilityHtmlTags.SecondaryHeading,
  };
};

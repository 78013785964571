import { AsyncComponentDataStatus } from '../../state/types';
import { listEventTimeSlots } from '../../../../adapters/listEventTimeSlots/listEventTimeSlots';
import { DailyAgendaActionFactory, DailyAgendaActions } from '../actions';

export const createFetchAvailableDaysAction: DailyAgendaActionFactory<
  DailyAgendaActions['fetchAvailableDays']
> =
  ({ flowAPI, getState, setState }) =>
  async () => {
    try {
      const response = await listEventTimeSlots({
        flowAPI,
        state: getState(),
        timeSlotsPerDay: 1,
      });
      setState({
        availableDays: {
          status: AsyncComponentDataStatus.IDLE,
          data: response,
        },
      });
    } catch (error) {
      setState({
        availableDays: { status: AsyncComponentDataStatus.ERROR, data: error },
      });
    }
  };

import {
  createSettingsParams,
  ISettingsParam,
  PartialBy,
  SettingsParamType,
} from '@wix/tpa-settings';
import { SlotsAvailability } from '../../types/types';
import { AccessibilityHtmlTags } from '../../utils/a11y/consts';

export type ISettingsParams = {
  timeSlotsDisplay: SlotsAvailability;
  limitTimeSlots: SettingsParamType.Boolean;
  maxTimeSlotsPerDay: SettingsParamType.Number;
  scrollBetweenDays: SettingsParamType.Boolean;
  itemsBeforeLoadMore: SettingsParamType.Number;
  selectedLocations: SettingsParamType.Text[];
  selectedCategories: SettingsParamType.Text[];
  titleText: SettingsParamType.Text;
  titleTagName: SettingsParamType.String;
  selectedServices: SettingsParamType.String[];
  timeSlotsPerDay: SettingsParamType.Number;
};

export type ISettingsParamsDefinitions = {
  [key in keyof ISettingsParams]: PartialBy<
    ISettingsParam<ISettingsParams[key]>,
    'key'
  >;
};

export const settingsParamsDefinitions: ISettingsParamsDefinitions = {
  timeSlotsDisplay: {
    getDefaultValue: () => SlotsAvailability.ALL,
  },
  limitTimeSlots: {
    getDefaultValue: () => true,
  },
  maxTimeSlotsPerDay: {
    getDefaultValue: () => 6,
  },
  scrollBetweenDays: {
    getDefaultValue: () => true,
  },
  itemsBeforeLoadMore: {
    getDefaultValue: () => 10,
  },
  selectedLocations: {
    getDefaultValue: () => [],
  },
  selectedCategories: {
    getDefaultValue: () => [],
  },
  selectedServices: {
    getDefaultValue: () => [],
  },
  timeSlotsPerDay: {
    getDefaultValue: () => 10,
  },
  titleText: {
    getDefaultValue: ({ t }) => t('app.settings.defaults.titleText'),
  },
  titleTagName: {
    getDefaultValue: () => AccessibilityHtmlTags.SecondaryHeading,
  },
};

export default createSettingsParams<ISettingsParams>(settingsParamsDefinitions);

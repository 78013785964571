import { AsyncComponentDataStatus } from '../../state/types';
import { listEventTimeSlots } from '../../../../adapters/listEventTimeSlots/listEventTimeSlots';
import { DailyAgendaActionFactory, DailyAgendaActions } from '../actions';

export const createFetchSlotsAction: DailyAgendaActionFactory<
  DailyAgendaActions['fetchSlots']
> =
  ({ flowAPI, getState, setState }) =>
  async () => {
    try {
      throw new Error('Not implemented');
      const response = await listEventTimeSlots({ flowAPI, state: getState() });
      setState({
        slots: { status: AsyncComponentDataStatus.IDLE, data: response },
      });
    } catch (error) {
      setState({
        slots: { status: AsyncComponentDataStatus.ERROR, data: error },
      });
    }
  };

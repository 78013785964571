import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { AsyncComponentDataStatus, DailyAgendaState } from './types';

export const initialStateFactory = ({
  flowAPI,
}: {
  flowAPI: ControllerFlowAPI;
}): DailyAgendaState => {
  const now = new Date();

  const nextWeek = new Date();
  nextWeek.setDate(nextWeek.getDate() + 7);

  return {
    slots: { status: AsyncComponentDataStatus.LOADING },
    availableDays: { status: AsyncComponentDataStatus.LOADING },
    selectedDate: now,
    selectedRange: { from: now, to: nextWeek },
    selectedTimezone: flowAPI.controllerConfig.wixCodeApi.site.timezone!, // TODO should be default time zone?
    selectedFilters: [],
  };
};

import { listEventTimeSlots as listEventTimeSlotsApi } from '@wix/ambassador-bookings-availability-v2-time-slot/http';
import { Filter, FilterType } from '../../types/types';
import settingsParams from '../../components/DailyAgenda/settingsParams';
import { ListEventTimeSlotsResponse } from '@wix/ambassador-bookings-availability-v2-time-slot/types';
import { BasicAdapterArgs } from '../types';

export type ListEventTimeSlotsArgs = BasicAdapterArgs & {
  timeSlotsPerDay?: number;
};

export const listEventTimeSlots = async ({
  flowAPI,
  state,
  timeSlotsPerDay,
}: ListEventTimeSlotsArgs): Promise<ListEventTimeSlotsResponse> => {
  // TODO add filters from queryParams

  const businessTimezone = flowAPI.controllerConfig.wixCodeApi.site.timezone;
  const { settings } = flowAPI;
  const { selectedRange, selectedFilters, selectedTimezone } = state;

  const serviceIds: string[] = [
    ...(settings.get(settingsParams.selectedServices) || []),
    ...getFiltersForType(selectedFilters, FilterType.SERVICE),
  ];

  const resourceIds: string[] = getFiltersForType(
    selectedFilters,
    FilterType.STAFF_MEMBER,
  );

  const locationIds: string[] = getFiltersForType(
    selectedFilters,
    FilterType.LOCATION,
  );

  const response = await flowAPI.httpClient.request(
    listEventTimeSlotsApi({
      fromLocalDate: DateToLocalDateISOString(
        selectedRange.from,
        businessTimezone,
      ),
      toLocalDate: DateToLocalDateISOString(selectedRange.to, businessTimezone),
      timeZone: selectedTimezone,
      ...(serviceIds.length > 0 ? { serviceIds } : undefined),
      eventFilter: {
        type: 'CLASS',
        ...(resourceIds.length > 0
          ? { 'resources.id': resourceIds }
          : undefined),
        ...(locationIds.length > 0
          ? { 'location.id': locationIds }
          : undefined),
      },
      timeSlotsPerDay,
    }),
  );

  return response.data;
};

const DateToLocalDateISOString = (date: Date, timeZone?: string) => {
  // locale sv formats the date as yyyy-mm-dd hh:mm:ss
  return date.toLocaleString('sv', { timeZone }).replace(' ', 'T');
};

const getFiltersForType = (filters: Filter[], type: FilterType) =>
  filters
    ?.find((filter) => filter.type === type)
    ?.options.map((filter) => filter.id) || [];

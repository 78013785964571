import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { GetState, SetState } from '../state/createState';
import { createOnSlotClickAction } from './onSlotClick/onSlotClick';
import { createOnFiltersChangeAction } from './onFiltersChange/onFiltersChange';
import { Filter } from '../../../types/types';
import { createFetchSlotsAction } from './fetchSlots/fetchSlots';
import { createFetchAvailableDaysAction } from './fetchAvailableDays/fetchAvailableDays';

export type DailyAgendaActions = {
  // incrementCounter: () => void;
  // decrementCounter: () => void;
  // setName: (name: string) => void;
  fetchSlots: () => Promise<void>;
  fetchAvailableDays: () => Promise<void>;
  onFiltersChange: (newSelectedFilters: Filter[]) => Promise<void>;
  onSlotClick: (slotId: string) => Promise<void>;
};

export type DailyAgendaActionFactory<ActionFN extends Function> = (args: {
  setState: SetState;
  getState: GetState;
  flowAPI: ControllerFlowAPI;
}) => ActionFN;

// const createIncrementCounter: DailyAgendaActionFactory<
//   DailyAgendaActions['incrementCounter']
// > =
//   ({ setState }) =>
//   () => {
//     setState((prevState) => ({
//       counter: prevState.counter + 1,
//     }));
//   };

// const createDecrementCounter: DailyAgendaActionFactory<
//   DailyAgendaActions['decrementCounter']
// > =
//   ({ setState }) =>
//   () => {
//     setState((prevState) => ({
//       counter: prevState.counter - 1,
//     }));
//   };

// const createSetName: DailyAgendaActionFactory<DailyAgendaActions['setName']> =
//   ({ setState }) =>
//   (name) => {
//     setState(() => ({
//       name,
//     }));
//   };

export const createDailyAgendaActions = (args: {
  setState: SetState;
  getState: GetState;
  flowAPI: ControllerFlowAPI;
}): DailyAgendaActions => ({
  onSlotClick: createOnSlotClickAction(args),
  onFiltersChange: createOnFiltersChangeAction(args),
  fetchSlots: createFetchSlotsAction(args),
  fetchAvailableDays: createFetchAvailableDaysAction(args),
  // incrementCounter: createIncrementCounter(args),
  // decrementCounter: createDecrementCounter(args),
  // setName: createSetName(args),
});

import { AsyncComponentDataStatus } from '../../state/types';
import { DailyAgendaViewModelFactory } from '../viewModel';

export type AgendaItemViewModel = {
  serviceName?: string;
  serviceImage?: { src: string; alt?: string };
  image?: any;
  startTime?: string;
  duration?: string;
  staffName?: string;
  staffImage?: string;
  location?: string;
  spots?: string;
  price?: string;
  buttonText?: string;
  disabled?: boolean;
  slotId: string;
};

export type DaySectionViewModel = {
  date: string;
  day: string;
  agendaItems: AgendaItemViewModel[];
};

export type BodyViewModel = {
  status: AsyncComponentDataStatus;
  days: DaySectionViewModel[];
};

export const createBodyViewModel: DailyAgendaViewModelFactory<
  BodyViewModel
> = ({ state: { slots }, flowAPI }) => {
  if (
    slots.status === AsyncComponentDataStatus.LOADING ||
    slots.status === AsyncComponentDataStatus.ERROR
  ) {
    return { status: slots.status, days: [] };
  }

  // const days = {};

  // slots.data.timeSlots?.forEach((slot) => {
  //   const startDate = new Date(slot.localStartDate!); // this is not utc. its local how to use timezone?
  //   const dayKey = `${startDate.getFullYear()}-${startDate.getMonth()}-${startDate.getDate()}`;

  //   const agendaItem: AgendaItemViewModel = {
  //     startTime: startDate.toLocaleTimeString(),
  //     slotId: slot.eventInfo?.eventId!,
  //     duration: getDuration(slot.localStartDate, slot.localEndDate),
  //   };
  // });

  return {
    status: slots.status,
    days: [],
  };
};

import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { DailyAgendaState, ViewModelFactoryArgs } from '../state/types';
import {
  createHeaderViewModel,
  HeaderViewModel,
} from './headerViewModel/headerViewModel';
import {
  BodyViewModel,
  createBodyViewModel,
} from './bodyViewModel/bodyViewModel';

export type DailyAgendaViewModel = {
  // counterViewModel: CounterViewModel;
  // nameViewModel: NameViewModel;
  headerViewModel: HeaderViewModel;
  bodyViewModel: BodyViewModel;
};

export type DailyAgendaViewModelFactory<ViewModel> = (
  args: ViewModelFactoryArgs,
) => ViewModel;

// type CounterViewModel = {
//   count: number;
//   isEven: boolean;
// };

// type NameViewModel = {
//   name: string;
// };

// const createCounterViewModel: DailyAgendaViewModelFactory<CounterViewModel> = ({
//   state,
// }) => {
//   console.log('counterViewModel');
//   return {
//     count: state.counter,
//     isEven: state.counter % 2 === 0,
//   };
// };

// const createNameViewModel: DailyAgendaViewModelFactory<NameViewModel> = ({
//   state,
// }) => {
//   console.log('nameViewModel');
//   return {
//     name: state.name,
//   };
// };

export const createDailyAgendaViewModel = (
  viewModelFactoryArgs: ViewModelFactoryArgs,
): DailyAgendaViewModel => {
  return {
    headerViewModel: createHeaderViewModel(viewModelFactoryArgs),
    bodyViewModel: createBodyViewModel(viewModelFactoryArgs),
    // counterViewModel: createCounterViewModel(viewModelFactoryArgs),
    // nameViewModel: createNameViewModel(viewModelFactoryArgs),
  };
};

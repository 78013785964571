import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { createState } from './state/createState';
import { initialStateFactory } from './state/initialStateFactory';
import {
  createDailyAgendaActions,
  DailyAgendaActions,
} from './actions/actions';
import {
  createDailyAgendaViewModel,
  DailyAgendaViewModel,
} from './viewModel/viewModel';

export type ControllerProps = {
  viewModel: DailyAgendaViewModel;
  actions?: DailyAgendaActions;
};

const createController: CreateControllerFn = async ({
  flowAPI,
  controllerConfig,
}) => {
  let tmpUnSubscribeFn: () => void;
  const unSubscribeFns: (() => void)[] = [];
  const unsubscribeToState = () => {
    unSubscribeFns.forEach((fn) => fn());
  };

  const setProps: (props: ControllerProps) => void = controllerConfig.setProps;
  return {
    async pageReady() {
      const initialState = initialStateFactory({ flowAPI });
      const { getState, setState, subscribe } = createState(initialState);
      const actions = createDailyAgendaActions({ getState, setState, flowAPI });
      const initialViewModel = createDailyAgendaViewModel({
        state: getState(),
        flowAPI,
      });

      setProps({ viewModel: initialViewModel, actions });

      tmpUnSubscribeFn = subscribe(
        (state) => state,
        () => {
          console.log('subscribe to all');
          // setProps({
          //   viewModel: createDailyAgendaViewModel({ state, flowAPI }),
          // });
        },
      );
      unSubscribeFns.push(tmpUnSubscribeFn);

      tmpUnSubscribeFn = subscribe(
        ({ slots }) => slots,
        () => {
          console.log('subscribe to slots');
          // setProps({
          //   viewModel: createDailyAgendaViewModel({ state, flowAPI }),
          // });
        },
      );
      unSubscribeFns.push(tmpUnSubscribeFn);

      tmpUnSubscribeFn = subscribe(
        ({ selectedFilters }) => selectedFilters,
        () => {
          console.log('subscribe to filters');
          // setProps({
          //   viewModel: createDailyAgendaViewModel({ state, flowAPI }),
          // });
        },
      );
      unSubscribeFns.push(tmpUnSubscribeFn);

      // actions.fetchSlots();
      // actions.fetchAvailableDays();
    },

    onBeforeUnLoad: () => {
      unsubscribeToState?.();
    },
  };
};

export default createController;

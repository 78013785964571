import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { Filter } from '../../../types/types';
import { ListEventTimeSlotsResponse } from '@wix/ambassador-bookings-availability-v2-time-slot/types';

export type DailyAgendaState = {
  selectedRange: { from: Date; to: Date };
  selectedFilters: Filter[];
  selectedTimezone: string;
  selectedDate: Date;
  slots: AsyncComponentData<ListEventTimeSlotsResponse>;
  availableDays: AsyncComponentData<ListEventTimeSlotsResponse>;
};

export type ViewModelFactoryArgs = {
  state: DailyAgendaState;
  flowAPI: ControllerFlowAPI;
};

type LoadedAsyncComponentData<T> = {
  data: T;
  status: AsyncComponentDataStatus.IDLE;
};

type LoadingAsyncComponentData = {
  status: AsyncComponentDataStatus.LOADING;
  data?: never;
};

type ErrorAsyncComponentData = {
  status: AsyncComponentDataStatus.ERROR;
  data?: never | unknown;
};

export type AsyncComponentData<T> =
  | LoadedAsyncComponentData<T>
  | LoadingAsyncComponentData
  | ErrorAsyncComponentData;

export enum AsyncComponentDataStatus {
  LOADING = 'loading',
  ERROR = 'error',
  IDLE = 'idle',
}
